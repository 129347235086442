body {
    font-family: "Merriweather", Sans-serif;
}
p {
    font-family: 'Arimo', sans-serif;
}
.navbar.navbar-light {
    border-bottom: 5px solid #fd907a;
}
.navbar-light .navbar-nav .nav-link {
    color: #fd907a;
    text-transform: uppercase;
}

.book-button {
    font-size: 14px;
    font-weight: 300;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    padding: 5px 10px ;
    border: 2px solid transparent;
}
.book-button:hover {
    text-decoration: none;
}

.font-headers-h1{
    font-size: 46px;
}

.font-family-arimo{
    font-family: 'Arimo', sans-serif;
}
.text-dark-blue {
    color:#001b4b;
}

.space-top {
    padding-top: 100px;
}
.space-bottom{
    padding-bottom: 100px;
}
.padding-bottom{
    padding-bottom: 60px;
}
.button {
    font-size: 14px;
    font-weight: 300;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 23px;
    border-radius: 25px 25px 25px 25px;
    padding: 12px 37px 12px 37px;
    border: 2px solid transparent;
}
.button:hover {
    text-decoration: none;
}
.button-red {
    background-color: #fd907a;
    color: white;
    border-color: transparent;
}
.button-red:hover{
    background-color: transparent;
    color: #fd907a;
    border-color: #fd907a;
}
.button-white {
    background-color: #fd907a;
    color: white;
    border-color: white;
}
.button-white:hover{
    background-color: white;
    color: #fd907a;
    border-color: white;
}

.link-red{
    color: #fd907a;
}

.link-red:hover{
    color: #fd907a;
}

.grecaptcha-badge {
    bottom:60px !important;
    display: none;
}
.banner{
    position: relative;
    overflow: hidden;
}
.banner-caption {
    position: absolute;
    text-align: center;
    width: 100%;
}
.banner img{
    width: 100%;
}
.banner h1 {
    padding: 30px 0;
}
.banner h4 {
    font-size: 18px;
    color: #333;
    font-family: 'Arimo', sans-serif;
}
.advices {
    background-color: #fff9f6;
}
.advice {
    text-align: center;
}
.advice:hover {
    background-color: #fd907a;
    background-image: url("/img/flower_bg.png");
    background-size: 100% auto;
    background-position: left bottom;
}
.advice h3{
    font-size: 26px;
    padding: 20px;
}
.advice p{
    padding: 20px;
    font-size: 16px;
    color: #616161;
}
.welcome {
    text-align: center;
}
.services-bg{
    background-image: url("/img/flower-bg.jpg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;
    background-color: #f6ede5;
}
.services {
    background-color: #fff;
    padding: 20px;
}
.services img{
    width: 100%;
}

.service {
    border:1px solid #ddd;
    overflow: hidden;
    margin-top: 20px;
}
.service h4, .service p{
    padding: 10px;
    margin: 0;

}
.service h5{
    font-size: 12px;
    display: inline-block;
    background-color: #ddd;
    color: #333;
    padding: 10px 20px;
    float: left;
    margin-bottom: 0;
}

.book{
    background-image: url("/img/figure-flower_bg.jpg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left bottom;
    color: white;
    background-color: #fd907a;
}

@media (max-width: 767px) {
    .banner img{
        width: auto;
        height: 400px;
    }
    .banner h1 {
        padding: 15px 0;
        font-size: 36px;
    }
}
@media (max-width: 480px) {
    .banner img{
        height: 300px;
    }
    .banner h1 {
        padding: 10px 0;
        font-size: 24px;
    }
    .banner h4 {
        font-size: 14px;
    }
}
.testimonial {
    text-align: center;
    background-color: #f6ede5;
}
.testimonial h5 {

    color: #777;
    margin: 0 0 10px 0;
    font-size: 26px;
}
.testimonial h2 {
    color: #333;
    margin: 0 0 60px 0;
    font-size: 38px;
}
.testimonial p {
    max-width: 70%;
    margin: 0 auto;
    font-size: 17px;
    color: #555;
    line-height: 30px;
    font-weight: 500;
}
.testimonial img{
    width: 100px;
    margin: 40px auto 15px auto;
    border-radius: 50%;
}
.testimonial h4{
    font-weight: 500;
    margin-bottom: 5px;
}
.testimonial .owl-controls {
    position: absolute;
    top: calc(50% - 35px);
    width: 100%;
}

.testimonial .owl-buttons div{
    font-size: 25px;
    display: inline-block;
}
.testimonial .owl-buttons .owl-prev{
    float: left;
}
.testimonial .owl-buttons .owl-next{
    float: right;
}
.testimonial .user {
    width: 100px;
    line-height: 100px;
    border: 1px solid #ddd;
    color: #ddd;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    font-size: 38px;
    margin: 40px auto 15px auto;
}

@media (max-width: 480px) {
    .testimonial p {
        max-width: 80%;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
    }
    .testimonial img{
        margin: 30px auto 10px auto;
    }
    .testimonial .user {
        margin: 30px auto 10px auto;
    }
}
.footer{
    background-color: #f6ede5;
    color: #888;
}
.footer h3{
    color: #333;
    font-size: 24px;
}
.footer a{
    text-transform: capitalize;
}
.footer-contacts i{
    display: inline-block;
    width: 35px;
    text-align: center;
    border: 2px solid #fd907a;
    color: #fd907a;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
}
.copyrights{
    border-top: 1px solid #ccc;
    padding-top: 10px;
    color: #ccc;
    font-size: 12px;
    padding-bottom: 10px;
}
